import DataService from './DataService'
export default {
  async fetchRegistrationUrlData(parameters) {
    let url = process.env.VUE_APP_API_BASE_URL + '/get_registration_url'
    let query = ''
    for (const [key, value] of Object.entries(parameters)) {
      if (!value) {
        continue
      }
      query += '&' + key + '=' + encodeURIComponent(value)
    }
    query = '?' + query.substr(1)
    return DataService.get(url + query)
  }
}
